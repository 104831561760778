import React, { useState, useEffect } from 'react'
import InformationTable from '../components/InformationTable';
import Title from '../components/styled/Title';
import useAuthContext from '../context/AuthContext';
import { useLoggedIn } from '../hooks/usePagePermission'
import { useTitle } from './../hooks/useTitle';

export default function Account() {
    const { user, getUser } = useAuthContext();
    useLoggedIn();

    useTitle('XtotheSpot Account Dashboard');

    return (<>
        <Title>Account Details</Title>
        <InformationTable data={[
            ['Username', user.username],
            ['Email', user.email]
        ]} />
    </>
    )
}
