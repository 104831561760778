import React, { useState } from 'react'
import EvenColumns from '../components/styled/EvenColumns';
import Form from './../components/forms/Form';
import Input from './../components/styled/Input';
import Label from './../components/styled/Label';
import Button from './../components/Button';
import useAxios from './../hooks/useAxios';
import usePagePermission from './../hooks/usePagePermission';
import useObject from './../hooks/useObject';
import styled from 'styled-components';
import Title from './../components/styled/Title';
import Spacer from './../components/styled/Spacer';
import Textarea from './../components/styled/Textarea';
import ImageUploadPreview from './../components/forms/ImageUploadPreview';
import { useNavigate } from 'react-router-dom';

const Description = styled(Textarea)`
    flex: 1;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function BrandNew() {
    usePagePermission('brand-store');
    const { fetch, loading } = useAxios();
    const { handleChange, setObject, object: formData } = useObject({
        name: "",
        description: "",
        image: ""
    });
    const navigate = useNavigate();

    return (
        <Form method="POST" onSubmit={handleSubmit} loading={loading}>
          <Title>Create Brand</Title>
          <Button type={"submit"} align={"right"}>Create</Button>
          <Spacer height={"1rem"}></Spacer>
          <EvenColumns columns={2} gap={"1rem"}>
            <ImageUploadPreview images={formData.image != "" ? [{ src: formData.image }] : []} maxImages={1} onChange={imageChange} />
            <Container>
              <EvenColumns columns={2} gap={"1rem"}>
                <Label htmlFor="name">Brand Name</Label>
                <Input name="name" value={formData.name} onChange={e => { handleChange(e.target.name, e.target.value) }} ></Input>
              </EvenColumns>
              <Label htmlFor="description">Description</Label>
              <Description name="description" value={formData.description} onChange={e => { handleChange(e.target.name, e.target.value) }}></Description>
            </Container>
          </EvenColumns>
        </Form>
      );
    
      function imageChange(images) {
        if (images.length === 0) {
          handleChange('image', "");
        } else {
          handleChange('image', images[0].src);
        }
      }

    function handleSubmit(e) {
        e.preventDefault();

        fetch("/api/v1/brands", {
            method: 'POST', data: formData
        }).then(response => {
            let newBrandId = response.data.data.id;
            setTimeout(() => {
                navigate(`/brands/${newBrandId}/edit`);
            }, 500)
        }).catch(error => {
        })
    }
}
