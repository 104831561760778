import React, { useEffect, useState } from 'react'
import useAxios from '../hooks/useAxios'
import { useSearchParams } from 'react-router-dom';
import SearchBar from './../components/SearchBar';
import StatusOverlay from './../components/StatusOverlay';
import ImageNotFound from '../assets/images/ImageNotFound.jpg'
import Center from './../components/styled/Center';
import Paginator from './../components/page/footer/Paginator';
import ImageCard from './../components/ImageCard';
import Title from './../components/styled/Title';
import ImageCardList from './../components/ImageCardList';
import Link from './../components/styled/Link';
import styled from 'styled-components';
import { useTitle } from './../hooks/useTitle';

const BrandCard = styled(Link)`
    max-width: 25rem;
`;

export default function Brands() {
    const [searchParams, setSearchParams] = useSearchParams();
    const { fetch, loading } = useAxios();
    const [brands, setBrands] = useState([]);
    const [meta, setMeta] = useState([]);
    useTitle('XtotheSpot Brands');

    useEffect(() => {
        let url = '/api/v1/brands?' + searchParams;
        fetch(url).then(handleResponse);
    }, [searchParams])

    function handleResponse(response) {
        setBrands(response.data.data);
        setMeta(response.data.meta);
    }

    function handleSearch(searchTerm) {
        setSearchParams({ ...searchParams, ['name[ct]']: searchTerm, ['page']: 1 });
    }

    return (
        <>
            <Center>
                <SearchBar
                    placeholder={'Search for brand...'}
                    onSearch={handleSearch} />
            </Center>
            <StatusOverlay
                loading={loading}
                overlayMessage={brands.length === 0 ? 'No results.' : null}>
                <ImageCardList columnWidth={'15rem'}>
                    {
                        brands.map(brand => {
                            return (
                                <BrandCard key={brand.id} to={`/brands/${brand.id}`}>
                                    <ImageCard imageSrc={brand.src}>
                                        <Title>{brand.name}</Title>
                                    </ImageCard>
                                </BrandCard>
                            )
                        })
                    }
                </ImageCardList>
                <Paginator meta={meta} onUpdatePage={handlePageChange} />
            </StatusOverlay>
        </>
    )

    function handlePageChange(page) {
        searchParams.set('page', page);
        setSearchParams(searchParams);
    }
}
