import React, { useEffect, useState } from "react";
import StatusOverlay from './../components/StatusOverlay';
import useAxios from './../hooks/useAxios';
import styled from 'styled-components';
import ProductEditor from './../components/forms/ProductEditor';

const Navigation = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
`;

export default function ProductReview() {
    const { fetch, loading } = useAxios();
    const [productData, setProductData] = useState([])
    const [currentProductIndex, setCurrentProductIndex] = useState(0);

    useEffect(() => { fetchProducts() }, []);

    return <StatusOverlay loading={loading}>
        <Navigation>
            <button onClick={next} >Next</button>
        </Navigation>
        <ProductEditor product={productData?.data?.[currentProductIndex]} />
    </StatusOverlay>;

    function next() {
        if (currentProductIndex < productData.data.length - 1) {
            setCurrentProductIndex(prevIndex => { return prevIndex + 1 });
        } else {
            setCurrentProductIndex(0);
            fetchProducts();
        }
    }

    function fetchProducts() {
        fetch(`/api/v1/products?status[eq]=pending`).then(response => {
            setProductData(response.data);
        });
    }
}
