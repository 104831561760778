import React from "react";
import styled from "styled-components";
import Colors from './../../../themes/Colors';

const StyledFooter = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${Colors.primary.medium};
    padding: 1rem;
`;

export default function Footer() {
    return (<StyledFooter>
        ©2022 xtothespot | All Rights Reserved
    </StyledFooter>);
}
