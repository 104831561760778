import React, { useEffect, useState } from 'react'
import SidebarLayout from '../layout/SidebarLayout';
import Title from './../../styled/Title';
import Subtitle from './../../styled/Subtitle';
import Spacer from './../../styled/Spacer';
import Button from './../../Button';
import ImageCardList from '../../ImageCardList';
import StatusOverlay from './../../StatusOverlay';
import ImageCard from './../../ImageCard';
import styled from 'styled-components';
import Colors from '../../../themes/Colors';
import { useSearchParams } from 'react-router-dom';
import Link from './../../styled/Link';
import Paginator from './../footer/Paginator';
import ImageNotFound from "../../../assets/images/ImageNotFound.jpg";
import useAxios from './../../../hooks/useAxios';

const CategoryLink = styled.a`
    display: block;
    user-select: none;
    cursor: pointer;
    color: ${Colors.primary.dark};
    padding: .2rem 1rem;
    :hover {
        text-decoration: underline;
        background-color: ${Colors.gray.light};
    }
`;

const ProductCard = styled(Link)`
    max-width: 35rem;
`;


export default function ProductExplorer({ search, onUpdateSearch }) {

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [data, setData] = useState([])
    const [meta, setMeta] = useState(null)
    const { fetch, loading } = useAxios();

    useEffect(() => {
        if (search) {

            fetch(`/api/v1/products?${search}`).then(handleResponse);

            fetch(`/api/v1/categories?${search}`).then(response => {
                setCategories(response?.data?.data);
            })

            if (search.has('category_id')) {
                fetch(`/api/v1/categories/${search.get('category_id')}`).then(response => {
                    setCategory(response?.data?.data);
                })
            } else {
                setCategory(null);
            }
        }
    }, [search])

    return (<SidebarLayout openText={'Filters'} closeText={'Hide Filters'}>
        <>
            <Title>Category</Title>
            <Subtitle fontSize={1.1}>
                {
                    breadcrumb(category).map((crumb, index) => {
                        return (<span key={crumb.id}>
                            {index !== 0 ? ' > ' : null}
                            <CategoryLink onClick={() => handleCategorySelection(crumb)}> {crumb.name}</CategoryLink>
                        </span>
                        );
                    })
                }
            </Subtitle>
            <Spacer height={'1rem'}></Spacer>
            {category &&
                <Button size={'small'} onClick={() => handleCategorySelection(null)}>Clear Category</Button>
            }
            {categories.map((item) => {
                return (
                    <React.Fragment key={item.id}>
                        <CategoryLink onClick={() => handleCategorySelection(item)} >{item.name}</CategoryLink>
                    </React.Fragment>
                );
            })}
        </>
        <StatusOverlay loading={loading} top={'10%'}>
            <ImageCardList columnWidth={"20rem"}>
                {data.map((product => {
                    return (
                        <ProductCard key={product.id} to={`/products/${product.id}`}>
                            <ImageCard imageSrc={product.images && (product.images[0].src ?? ImageNotFound)}>
                                <Title>{product.title}</Title>
                                <span>{getLowestPrice(product) ?? 'No price data.'}</span>
                            </ImageCard>
                        </ProductCard>
                    )
                }))}
            </ImageCardList>
            <Paginator meta={meta} onUpdatePage={handlePageChange} />
        </StatusOverlay >
    </SidebarLayout>
    )

    function handlePageChange(page) {
        search.set('page', page);
        onUpdateSearch(search);
    }

    function handleResponse(response) {
        setData(response.data.data);
        setMeta(response.data.meta);
    }

    function handleCategorySelection(category) {
        if (category == null) {
            search.delete('category_id');
        } else {
            search.set('category_id', category.id);
        }
        search.set('page', 1);
        onUpdateSearch(search);
    }

    function getLowestPrice(product) {
        if (!product?.listings) return null;

        let lowest = null;

        product.listings.forEach(listing => {
            if (listing.price?.price) {
                if (lowest == null || listing.price.price < lowest) {
                    lowest = listing.price.price;
                }
            }
        })

        if (lowest) {
            return `$${lowest}`;
        }
        return lowest;
    }

    function breadcrumb(category, crumbs = []) {
        if (!category) return [];

        crumbs.unshift(category)
        if (!category.category) {
            return crumbs;
        }

        return breadcrumb(category.category, crumbs);
    }
}
