import React, { useState } from "react";
import { useEffect } from "react";
import useAxios from './../hooks/useAxios';
import styled from "styled-components";
import Colors from './../themes/Colors';

const CategoryList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 20rem;
    overflow-y: scroll;
`;

const CategoryItem = styled.button`
    width: 100%;
    text-align: left;
    background-color: white;
    border: 1px solid black;
    height: 2rem;
    cursor: pointer;

    :hover {
        background-color: ${Colors.primary.light};
    }
`;

const Crumb = styled.button`
    border: 0;
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
`;


export default function CategoryExplorer({onSelect}) {
    const [categories, setCategories] = useState([]);
    const [path, setPath] = useState([{name: 'Top Level', id: ''}]);
    const { fetch, loading } = useAxios();

    useEffect(() => {
        fetchCategories('');
    }, []);

    return (
        <div>
            <div>
                {path.map((crumb, index) => {
                    return <span key={crumb.id}>
                        <Crumb
                            onClick={() => handleCrumbSelect(crumb)}
                        >{crumb.name}</Crumb>
                        {index != path.length - 1 ? '>' : ''}
                    </span>
                })}
            </div>
            <CategoryList>
                {
                    categories.length > 0 ?
                        categories.map(category => {
                            return <li key={category.id}><CategoryItem
                                type="button"
                                onClick={() => { handleSelect(category) }}
                            >
                                {category.name}
                            </CategoryItem></li>
                        }) : 'No more subcategories.'
                }
            </CategoryList>
        </div>
    );

    function handleSelect(category) {
        path.push(category);
        setPath(path);

        fetchCategories(category.id);
    }

    function handleCrumbSelect(crumb) {
        path.splice(path.indexOf(crumb) + 1);

        fetchCategories(crumb.id);
    }

    function fetchCategories(categoryId) {
        if(categoryId !== '' && onSelect) {
            onSelect(categoryId);
        }

        fetch(`/api/v1/categories?category_id=${categoryId}`).then(response => {
            setCategories(response.data.data);
        })
    }
}
