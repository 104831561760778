import styled from 'styled-components';
import React from 'react'

const Container = styled.div`
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: #EEEA;
`;

export default function Overlay({ className, children, ...props }) {
    return (
        <Container className={className} {...props}>{children}</Container>
    )
}
