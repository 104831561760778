import styled from 'styled-components';
import Colors from './../../themes/Colors';

const Title = styled.div`
font-size: ${props => props.fontSize || 1.2}rem;
font-weight: bold;
border-bottom: 1px solid ${Colors.primary.dark};
margin-bottom: ${props => props.marginBottom || 1}rem;
`;


export default Title;