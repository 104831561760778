import React, { useState } from "react";
import Title from "../components/styled/Title";
import styled from "styled-components";
import ScaledSquareImage from "../components/styled/ScaledSquareImage";
import Grid from './../components/styled/Grid';
import { useEffect } from "react";
import { useParams, useSearchParams, navigate } from 'react-router-dom';
import useAxios from "../hooks/useAxios";
import SidebarLayout from "../components/page/layout/SidebarLayout";
import ProductExplorer from "../components/page/feature/ProductExplorer";
import SearchBar from "../components/SearchBar";
import { useNavigate, createSearchParams } from 'react-router-dom';
import StatusOverlay from "../components/StatusOverlay";
import { useTitle } from './../hooks/useTitle';

const StorefrontInfoContainer = styled(Grid)`

    @media only screen and (min-width: 767px) {
        grid-template-columns: 1fr 3fr;

        ${ScaledSquareImage} {
            max-height: 20rem;
        }
    }
`;

export default function Storefront() {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { fetch, loading } = useAxios();
    const [storefront, setStorefront] = useState({});
    const [productSearch, setProductSearch] = useState();
    const navigate = useNavigate();

    useTitle(storefront.name);

    useEffect(() => {
        fetch(`/api/v1/storefronts/${params.id}`).then(response => {
            let storefront = response.data?.data;
            if (storefront) {
                setStorefront(storefront);
            }
        })
    }, [params])

    useEffect(() => {
        searchParams.set('storefront_id[eq]', params.id);
        setProductSearch(searchParams);
    }, [searchParams])

    return (<StatusOverlay loading={loading}>
        <div>
            <StorefrontInfoContainer gap={'2rem'}>
                <div>
                    <ScaledSquareImage src={storefront.src}></ScaledSquareImage>
                </div>
                <div>
                    <Title fontSize={1.8}>{storefront.name}</Title>
                    <p>{storefront.description}</p>
                </div>
            </StorefrontInfoContainer>
        </div>
        <SearchBar placeholder={`Search for a product carried by ${storefront.name}`} onSearch={handleSearch} />
        <ProductExplorer search={productSearch} onUpdateSearch={(searchParams) => { setSearchParams(searchParams) }} />
    </StatusOverlay>);

    function handleSearch(searchTerm) {
        let query = { page: 1, 'title[ct]': searchTerm };
        navigate({
            pathname: window.location.pathname,
            search: `?${createSearchParams(query)}`
        });
    }
}