import React from "react";
import ScaledSquareImage from "./styled/ScaledSquareImage";
import ImageNotFound from "./../assets/images/ImageNotFound.jpg";

export default function ImageCard({ imageSrc, children }) {
    return (
        <>
            <ScaledSquareImage
                loading="lazy"
                src={imageSrc ?? ImageNotFound}
                onError={(e) => e.target.src = ImageNotFound}
            />
            {children}
        </ >
    )
}
