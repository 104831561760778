import React from "react";
import styled from "styled-components";
import Colors from './../themes/Colors';

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid ${Colors.primary.light};

    tbody>*:nth-child(odd) {
        background-color: ${Colors.primary.light};
    }

    thead {
        color: white;
        background-color: ${Colors.primary.medium};
    }

    tbody td, thead th {
        padding: .5rem;
    }

    ${props => props.centerData ? 
        `tbody td > * {
            display: block;
            margin-inline: auto;
        }` : ''
    }
`;


export default function InformationTable({ headers, data, centerData }) {

    headers = headers || [];

    return (
        <Table centerData={centerData}>
            <thead>
                <tr>
                    {generateHeaders(headers)}
                </tr>
            </thead>
            <tbody>
                {data.map((row, index) => {
                    return (<tr key={index}>
                        {generateRow(row)}
                    </tr>)
                })}
            </tbody>
        </Table>
    );


    function generateRow(row) {
        return row.map((dataPoint, index) => {
            return (
                <td key={index}>{dataPoint}</td>
            )
        })
    }

    function generateHeaders(headers) {
        return headers.map((dataPoint, index) => {
            return (
                <th key={index}>{dataPoint}</th>
            )
        })
    }
}
