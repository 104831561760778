import React, { useEffect, useState } from 'react'
import Loading from './../../pages/Loading';
import Form from './Form';
import ProductForm from './ProductForm';
import useObject from './../../hooks/useObject';
import useAxios from './../../hooks/useAxios';
import usePagePermission from './../../hooks/usePagePermission';


export default function ProductEditor({ product }) {

    const { validating, validated } = usePagePermission('product-update');
    const [initialProduct, setInitialProduct] = useState({});
    const { fetch, loading } = useAxios();
    const { handleChange, setObject, object: formData } = useObject();

    useEffect(() => {
        setObject(product);
        setInitialProduct(product);
    }, [product]);



    if (validating || !validated) {
        return <Loading />
    } else {
        return (
            <Form method={'PUT'} loading={loading} onSubmit={e => handleSubmit(e)}>
                <div>
                    <select name='status' onChange={(e) => handleChange(e.target.name, e.target.value)} value={formData?.status}>
                        <option value={'active'}>Active</option>
                        <option value={'inactive'}>Inactive</option>
                        <option value={'pending'}>Pending</option>
                        <option value={'rejected'}>Rejected</option>
                    </select>
                </div>
                <ProductForm
                    onChange={handleChange}
                    formData={formData}
                    submitText={'Save'}
                ></ProductForm>
            </Form>
        )
    }

    function handleSubmit(e) {
        e.preventDefault();

        editVideo();
        editDescription()
        editProduct();
        removeImages().then(() => editImages());
    }

    function editVideo() {
        if (formData?.video == initialProduct.video) return;
        if (!formData?.video?.src || !formData?.video?.title) return;

        putOrPost('/api/v1/product/videos', initialProduct?.video?.id, { ...formData?.video, product_id: initialProduct.id });
    }

    function editDescription() {
        if (formData?.description == initialProduct.description) return;
        if (!formData?.description?.body) return;

        putOrPost('/api/v1/product/descriptions', initialProduct?.description?.id, { ...formData?.description, product_id: initialProduct.id });
    }

    function editProduct() {
        putOrPost('/api/v1/products', initialProduct.id, {
            title: formData?.title,
            upc: formData?.upc,
            model: formData?.model,
            brand_id: formData?.brand_id,
            weight: formData?.weight,
            length: formData?.length,
            width: formData?.width,
            height: formData?.height,
            status: formData?.status
        });
    }

    function removeImages() {
        return new Promise((resolve, reject) => {
            if (!initialProduct.images) {
                resolve();
                return;
            }

            let formImages = formData?.images ?? [];

            // delete any images that are in the inital data but not the current form data
            let promises = [];
            promises.push(initialProduct.images.map((image, index) => {
                if (!formImages.some((formImage) => image == formImage)) {
                    return fetch(`/api/v1/product/images/${image.id}`, { method: "DELETE" });
                }
            }));

            Promise.allSettled(promises).then(
                resolve()
            );
        })
    }

    function editImages() {
        return new Promise((resolve, reject) => {
            let initalImages = initialProduct.images ?? [];

            if (!formData?.images) {
                resolve();
                return;
            }

            let promises = [];
            promises.push(formData?.images.map((image, index) => {
                if (!initalImages.some((initalImage) => image == initalImage)) {
                    return fetch('/api/v1/product/images', { method: 'POST', data: { ...image, product_id: initialProduct.id } })
                }
            }));

            Promise.allSettled(promises).then(
                resolve()
            );
        })
    }


    function putOrPost(url, resourceId, data) {
        return new Promise((resolve, reject) => {
            let method = 'POST';
            if (resourceId) {
                url = `${url}/${resourceId}`;
                method = 'PUT';
            }

            fetch(url, { method: method, data: data })
                .then(response => {
                    resolve();
                })
                .catch(err => {
                    reject()
                });
        })
    }

}
