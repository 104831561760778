import styled from 'styled-components';

const Center = styled.div`
    display:flex;
    width: 100%;
    justify-content:center;
    margin-inline: auto;
    padding:${props => props.padding ? props.padding : 0};
`;

export default Center;