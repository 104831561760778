import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Title from './../components/styled/Title';
import useObject from '../hooks/useObject';
import CenterDiv from './../components/styled/CenterDiv';
import useAuthContext from '../context/AuthContext';
import EvenColumns from './../components/styled/EvenColumns';
import Spacer from './../components/styled/Spacer';
import Form from './../components/forms/Form';
import Button from './../components/Button';
import { useTitle } from './../hooks/useTitle';

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [overlayMessage, setOverlayMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const { user, login } = useAuthContext();
    const navigate = useNavigate();
    const { handleChange, object: formData } = useObject();

    useTitle('XtotheSpot Login');

    useEffect(() => {
        if(user) {
            navigate("/");
        }
    }, [])

    function onChange(e) {
        handleChange(e.target.name, e.target.value);
    }

    function submitLogin(e) {
        e.preventDefault();
        setLoading(true);
        login(formData).then(
            (response) => {
                setLoading(false)
                if (response?.data?.errors) {
                    setErrorMessage('Invalid Email or Password.');
                }
            }
        ).catch(
            () => { setLoading(false) }
        );

    }

    return (
        <Form
            loading={loading}
            overlayMessage={overlayMessage}
            onSubmit={submitLogin}>
            <CenterDiv padding={'1rem'}>
                <Title>Login</Title>
                <EvenColumns columns={2}>
                    <label htmlFor="email">Email:</label>
                    <input type="email" name='email' placeholder='Email' onChange={onChange} />
                    <label htmlFor="password">Password:</label>
                    <input type="password" name='password' placeholder='Password' onChange={onChange} />
                </EvenColumns>
                {errorMessage &&
                    <><Spacer height={'1rem'} />
                        <span>{errorMessage}</span></>
                }
                <Spacer height={'1rem'} />
                <p>Don't have an account? <Link to={"/register"}>Sign up here.</Link></p>
                <Button margin={'1rem'} type={'submit'} align={'right'} >Log In</Button>
            </CenterDiv>
        </Form >
    )
}
