import React, { useEffect, useRef, useState } from 'react'
import { useLoggedIn } from '../hooks/usePagePermission';
import Title from '../components/styled/Title';
import { Link } from 'react-router-dom';
import useAuthContext from '../context/AuthContext';
import Grid from '../components/styled/Grid';
import { useTitle } from '../hooks/useTitle';
import Label from '../components/styled/Label';
import Flex from '../components/styled/Flex';
import useAxios from '../hooks/useAxios';

export default function Catalog() {

    const [productDataFile, setProductDataFile] = useState('');
    const [productDataFileError, setProductDataFileError] = useState('');
    const productDataFileInput = useRef();
    const { fetch } = useAxios();

    const { user } = useAuthContext();
    useLoggedIn();
    useTitle('XtotheSpot Catalog Dashboard');

    return (<Grid gap={'1rem'}>
        <Title>Products</Title>
        {holdsPermission('product-store') && <Link to={"/products/new"}>New Product</Link>}
        {holdsPermission('product-update') && <Link to={"/products/review"}>Review Products</Link>}
        {holdsPermission('product-store') &&
            <Flex gap={'1rem'}>
                <Label htmlFor='product-data-file'>Upload Products (.csv)</Label>
                <input ref={productDataFileInput} name='product-data-file' type={'file'} accept=".csv" onChange={(e) => setProductDataFile(e.target.files[0])} />
                <button onClick={handleProductDataUpload}>Upload</button>
                {productDataFileError}
            </Flex>
        }
        <Title>Brands</Title>
        {holdsPermission('brand-store') && <Link to={"/brands/new"}>New Brand</Link>}
    </Grid>
    )

    function holdsPermission(permission) {
        if (!user) return false;

        return user.permissions?.includes(permission);
    }

    function handleProductDataUpload() {
        if (!productDataFile) return;
        setProductDataFileError('')

        let formData = new FormData();
        formData.append('productDataFile', productDataFile, productDataFile.name);
        fetch('/api/v1/product/upload', { method: 'POST', data: formData }).then(response => {
            setProductDataFile('');
            productDataFileInput.current.value = null;
        }).catch(error => {
            setProductDataFileError('Upload Failed.')
        })
    }
}
