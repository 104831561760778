import { useState } from "react";

const useObject = (initialObject) => {
    const [object, setObject] = useState(initialObject || {});

    function handleChange(key, value) {
        const keys = key.split(".");

        setObject((prevObject) => {
            return updateValue(prevObject, keys, value);
        });
    };

    const updateValue = (obj, keys, value) => {
        if (keys.length === 1) {
            return { ...obj, [keys[0]]: value };
        }

        return {
            ...obj,
            [keys[0]]: updateValue(obj[keys[0]], keys.slice(1), value)
        };
    };

    const getValue = (key, obj = null) => {
        if (!obj) {
            obj = object;
        }
        const keys = key.split(".");

        if (keys.length === 1) {
            return obj[keys[0]]
        }

        return getValue(keys.slice(1).join("."), obj[keys[0]]);
    }

    return { handleChange, setObject, getValue, object };
}

export default useObject;