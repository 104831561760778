import React, { useEffect, useState } from 'react'
import ProductBrandEditor from './ProductBrandEditor';
import ImageUploadPreview from './ImageUploadPreview';
import Title from '../styled/Title';
import Label from '../styled/Label';
import Textarea from '../styled/Textarea';
import ObjectArrayEditor from './ObjectArrayEditor'
import styled from 'styled-components';
import GridArea from '../styled/GridArea';
import Button from '../Button';
import useAxios from '../../hooks/useAxios';
import EvenColumns from './../styled/EvenColumns';
import CategoryExplorer from '../CategoryExplorer';
import Spacer from './../styled/Spacer';

const Layout = styled.div`
    display:grid;
    grid-template-areas: 
        'submit'
        'images'
        'description'
        'details'
        'brand'
        'video'
        'category';

    gap: 1rem;

    @media only screen and (min-width: 767px) {
        grid-template-areas:
            '. submit'
            'images images'
            'description description'
            'details brand'
            'details video'
            'category .';
    }

    @media only screen and (min-width: 1200px) {
        grid-template-areas:
            '. . submit'
            'images images images'
            'description description details'
            'brand brand category'
            'video video category';
    }
`;

export default function ProductForm({ onChange, formData, submitText }) {
    formData = formData || {};

    const { fetch, loading } = useAxios();
    const [storefronts, setStorefronts] = useState([]);
    const [category, setCategory] = useState(null);

    function handleChange(key, value) {
        onChange(key, value);
    }

    useEffect(() => {
        fetch('/api/v1/storefronts').then(response => {
            setStorefronts(response.data.data);
        });
    }, []);

    return (
        <Layout>
            <GridArea gridArea={'submit'}>
                <Button type={'submit'} align={'right'}>{submitText || 'Submit'}</Button>
            </GridArea>
            <GridArea gridArea={'images'}>
                <Title>Images</Title>
                <ImageUploadPreview maxImages={6} images={formData.images} onChange={(images) => handleChange('images', images)} ></ImageUploadPreview>
            </GridArea>
            <GridArea gridArea={'description'}>
                <Title>Description</Title>
                <Textarea
                    value={formData.description?.body || ""}
                    name={'description.body'}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                ></Textarea>
            </GridArea>
            <GridArea gridArea={'details'}>
                <Title>Details</Title>
                <EvenColumns columns={2}>
                    <Label>UPC</Label>
                    <input required minLength={12} maxLength={12} value={formData.upc || ""} name={'upc'} type={'text'} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label>Title</Label>
                    <input required value={formData.title || ""} name={'title'} type={'text'} minLength={10} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label>Model</Label>
                    <input value={formData.model || ""} name={'model'} type={'text'} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label>Weight (lbs)</Label>
                    <input value={formData.weight || 0} name={'weight'} type={'number'} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label>Length (in.)</Label>
                    <input value={formData.length || 0} name={'length'} type={'number'} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label>Width (in.)</Label>
                    <input value={formData.width || 0} name={'width'} type={'number'} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label>Height (in.)</Label>
                    <input value={formData.height || 0} name={'height'} type={'number'} onChange={e => handleChange(e.target.name, e.target.value)} />
                </EvenColumns>
            </GridArea>
            <GridArea gridArea={'brand'}>
                <Title>Brand</Title>
                <ProductBrandEditor brandId={formData.brand_id || null} onBrandChange={(id) => handleChange('brand_id', id)} ></ProductBrandEditor>
            </GridArea>
            <GridArea gridArea={'video'}>
                <Title>Video</Title>
                <EvenColumns columns={2}>
                    <Label>Title</Label>
                    <input value={formData.video?.title || ""} name={'video.title'} type={'text'} onChange={e => handleChange(e.target.name, e.target.value)} />
                    <Label title='https://www.youtube.come/embed/{embedId}'>Embed Link</Label>
                    <input value={formData.video?.src || ""} name={'video.src'} type={'text'} onChange={e => handleChange(e.target.name, e.target.value)} />
                </EvenColumns>
            </GridArea>
            <GridArea gridArea={'category'}>
                <Title>Category</Title>
                <CategoryExplorer onSelect={setCategory} />
                <Spacer height={'.5rem'} />
                <Button align={'right'}>Add to Category</Button>
            </GridArea>
        </Layout>
    )
}
