import React from 'react'
import styled from 'styled-components'

const Label = styled.label`
    ::after {
        content: ':';
    }

    cursor: ${props => props.title ? 'help' : 'default'}
`;


export default Label;
