import styled from 'styled-components';
import React from 'react';
import Colors from './../themes/Colors';

const BaseButton = styled.button`
    display: block;
    cursor: pointer;
    border: 0;
    width: min-content;
    white-space: nowrap;
    border-radius: .5rem;
    padding: .5rem .5rem;

    ${props => {
        if (props.align === 'right') {
            return 'margin-inline: auto 0;'
        } else if (props.align === 'center') {
            return 'margin-inline: auto;'
        } else if (props.align === 'left') {
            return 'margin-inline: 0 auto;'
        }
    }};

    ${props => {
        if (props.size === 'small') {
            return `
                padding: .1rem .2rem;
            `;
        }
    }}
`;

const DefaultButton = styled(BaseButton)`
    background-color: ${Colors.primary.dark};
    color: white;

    :hover {
        background-color: ${Colors.primary.medium}
    }
`;

const HighlightButton = styled(BaseButton)`
    background-color: #FBC469;
    border: 1px solid ${Colors.primary.darker};

    :hover {
        background-color: #F9F871;
    }
`;

export { HighlightButton };

export default function Button({ type, children, ...props }) {
    return <DefaultButton type={type || 'button'} {...props}>{children}</DefaultButton>
}
