import React, { useEffect, useState } from 'react'
import styles from './SearchableSelect.module.css';

export default function SearchableSelect({ onChange, input, suggestions }) {
    return (
        <div>
            <div className={styles.container}>
                <input type="text" onChange={e => onChange(e.target.value)} value={input} />
                {suggestions.length > 0 && <div className={styles.suggestions}>
                    {suggestions.map(suggestion => {
                        return <p
                            key={suggestion.id}
                            onClick={() => onChange(suggestion.value)}
                            className={styles.suggestion}> {suggestion.value} </p>
                    })}
                </div>}
            </div>
        </div>
    )
}
