import React from 'react'
import styled from 'styled-components'
import Colors from '../../../themes/Colors'
import EvenColumns from '../../styled/EvenColumns';
import Link from '../../styled/Link';
import Title from '../../styled/Title';

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: ${Colors.primary.darker};
    color: ${Colors.primary.light};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const FooterLink = styled(Link)`
    text-decoration: underline;
`;

export default function FooterLinks() {
    return (
        <Container>
            <EvenColumns columns={2}>
                <Column>
                    <Title>Navigation:</Title>
                    <FooterLink to={'/products'}>Products</FooterLink>
                    <FooterLink to={'/brands'}>Brands</FooterLink>
                    <FooterLink to={'/login'}>Login</FooterLink>
                    <FooterLink to={'/register'}>Register</FooterLink>
                </Column>
                <Column>
                <Title>XtotheSpot:</Title>
                Contact: info@xtothespot.com
                </Column>
            </EvenColumns>
        </Container>
    )
}
