import React, { useState } from "react";
import ImageUploadPreview from "../components/forms/ImageUploadPreview";
import EvenColumns from "../components/styled/EvenColumns";
import Input from "../components/styled/Input";
import Label from "../components/styled/Label";
import Textarea from "../components/styled/Textarea";
import Form from './../components/forms/Form';
import styled from "styled-components";
import Button from './../components/Button';
import useAxios from './../hooks/useAxios';
import useObject from './../hooks/useObject';
import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import usePagePermission from './../hooks/usePagePermission';

const Description = styled(Textarea)`
    flex: 1;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;


export default function StorefrontEdit() {

    usePagePermission('storefront-update');

    const { fetch, loading } = useAxios();
    const params = useParams();
    const [initialStorefront, setInitialStorefront] = useState({});
    const { handleChange, setObject, object: formData } = useObject({
        name: "",
        url: "",
        description: "",
        image: ""
    });

    useEffect(() => {
        fetch(`/api/v1/storefronts/${params.id}`).then(response => {
            let storefront = response.data?.data;
            let storefrontObject = {
                name: storefront.name ?? "",
                url: storefront.url ?? "",
                description: storefront.description ?? "",
                image: storefront.src ?? "",
            };
            setObject(storefrontObject);
            setInitialStorefront(storefrontObject);
        })
    }, [params])


    return (<Form method="POST" onSubmit={handleSubmit} loading={loading}>
        <Button type={"submit"} align={"right"}>Edit</Button>
        <EvenColumns columns={2} gap={"1rem"}>
            <ImageUploadPreview images={formData.image != "" ? [{ src: formData.image }] : []} maxImages={1} onChange={imageChange} />
            <Container>
                <EvenColumns columns={2} gap={"1rem"}>
                    <Label htmlFor="name">Storefront Name</Label>
                    <Input name="name" value={formData.name} onChange={e => { handleChange(e.target.name, e.target.value) }} ></Input>
                    <Label htmlFor="url">Homepage URL</Label>
                    <Input name="url" value={formData.url} onChange={e => { handleChange(e.target.name, e.target.value) }}></Input>
                </EvenColumns>
                <Label htmlFor="description">Description</Label>
                <Description name="description" value={formData.description} onChange={e => { handleChange(e.target.name, e.target.value) }}></Description>
            </Container>
        </EvenColumns>
    </Form>);

    function handleSubmit(e) {
        e.preventDefault();

        let patchData = {};
        if (formData.name !== initialStorefront.name) {
            patchData.name = formData.name;
        }
        if (formData.url !== initialStorefront.url) {
            patchData.url = formData.url;
        }
        if (formData.description !== initialStorefront.description) {
            patchData.description = formData.description;
        }
        if (formData.image !== initialStorefront.image) {
            patchData.image = formData.image;
        }
        if (Object.keys(patchData).length !== 0) {
            fetch(`/api/v1/storefronts/${params.id}`, {
                method: "PATCH",
                data: patchData
            });
        }
    }

    function imageChange(images) {
        if (images.length === 0) {
            handleChange('image', "");
        } else {
            handleChange('image', images[0].src);
        }
    }
}
