import React, { useState } from "react";
import styled from "styled-components";
import ImageNotFound from "../assets/images/ImageNotFound.jpg"
import BoxShadow from "../themes/BoxShadow";
import Colors from "../themes/Colors";
import ScaledSquareImage from "./styled/ScaledSquareImage";

const GalleryLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 6fr;
    gap: 1rem;
`;

const SidebarLayout = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: repeat(6, 1fr);
`;

const SidebarImage = styled(ScaledSquareImage)`
    box-shadow: ${BoxShadow.size.small} ${Colors.gray.light};
    cursor: pointer;

    :hover {
        box-shadow: ${BoxShadow.size.small} ${Colors.gray.dark};
    }
`;

const MainImage = styled(ScaledSquareImage)`
    background-color: #EEE;
`;

const ImageGallery = ({ images }) => {
    const [mainImage, setMainImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);

    images = images || [];

    if (images.length > 1) {
        return (
            <GalleryLayout>
                <SidebarLayout>
                    {images.map(image => (
                        <SidebarImage
                            key={image.id}
                            alt=""
                            src={image.src}
                            onMouseEnter={() => setPreviewImage(image.src)}
                            onMouseLeave={() => setPreviewImage(null)}
                            onClick={() => setMainImage(image.src)}
                            onError={e => { e.target.src = ImageNotFound }}
                        />
                    ))}
                </SidebarLayout>
                <MainImage src={previewImage ?? mainImage ?? images[0]?.src ?? ImageNotFound}
                    onError={e => { e.target.src = ImageNotFound }} />
            </GalleryLayout>
        );
    } else {
        return (
            <div>
                <MainImage src={previewImage ?? mainImage ?? images[0]?.src ?? ImageNotFound}
                    onError={e => { e.target.src = ImageNotFound }} />
            </div>
        );
    }
};

export default ImageGallery;