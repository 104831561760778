import React, { useEffect } from 'react'
import styled from 'styled-components';
import Button from '../Button';
import useObject from '../../hooks/useObject';
import EvenColumns from '../styled/EvenColumns';

const AddButton = styled(Button)`
white-space: nowrap;
margin-left: auto;
`;

export default function ObjectArrayEditor({ baseKey, array, labels, row, onChange }) {
    baseKey = baseKey || "";
    labels = labels || [];
    array = array || [];

    function handleNewRow() {
        array.push({});
        onChange(array);
    }

    function handleDelete(index) {
        onChange(array.filter((a, i) => {
            return index !== i;
        }))
    }

    function handleChange(object, index) {
        onChange(array.map((a, i) => {
            return index === i ? object : a;
        }))
    }

    return (
        <EvenColumns columns={labels.length + 1}>
            {labels.map(label => {
                return label;
            })}
            <AddButton align={'center'} onClick={handleNewRow} >New Row</AddButton>

            {array.map((val, index) => {
                return <React.Fragment key={index}>
                    <DataRow onChange={(object) => handleChange(object, index)} obj={val} row={row}></DataRow>
                    <Button align={'center'} onClick={() => handleDelete(index)} >X</Button>
                </React.Fragment>
            })}
        </EvenColumns>
    )
}

const DataRow = ({ obj, row, onChange }) => {

    const { object, handleChange, getValue } = useObject(obj);

    useEffect(() => {
        onChange(object);
    }, [object])

    return (row.map((field, index) => {
        if (field.props.type === 'checkbox') {
            return React.cloneElement(field, { key: index, onChange: (e) => handleChange(e.target.name, e.target.checked), checked: getValue(field.props.name) || false })
        } else {
            return React.cloneElement(field, { key: index, onChange: (e) => handleChange(e.target.name, e.target.value), value: getValue(field.props.name) || '' })
        }
    })
    )
}