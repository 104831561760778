import React, { useState, useEffect } from 'react';
import axios from '../api/axios';

const useAxios = () => {
  const [loading, setLoading] = useState(0);

  const fetch = (url, options) => {
    setLoading(prevLoading => { return prevLoading + 1 });
    return axios(url, { ...options })
      .then(response => {
        setLoading(prevLoading => { return prevLoading - 1 });
        return response;
      })
      .catch(error => {
        setLoading(prevLoading => { return prevLoading - 1 });
        throw error;
      });
  };

  return { fetch, loading: loading > 0 ? true : false };
};

export default useAxios;
