import React from 'react'
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from './../../Button';
import CenterDiv from './../../styled/CenterDiv';
import EvenColumns from './../../styled/EvenColumns';

const Visibility = styled.div`
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
`;

const PageInfo = styled.span`
    white-space: nowrap;
`;

export default function Paginator({ meta, onUpdatePage }) {
    if (!meta || meta.last_page === 1) { return <></> }

    return (
        <CenterDiv>
            <EvenColumns columns={3} alignItems={'center'} gap={'1rem'}>
                <Visibility visible={meta.current_page > 1 && meta.last_page > 1}>
                    <Button
                        onClick={handlePrevious}>
                        &laquo; Previous
                    </Button>
                </Visibility>
                <PageInfo>
                    Page {meta.current_page} of {meta.last_page}
                </PageInfo>
                <Visibility visible={meta.current_page < meta.last_page}>
                    <Button
                        onClick={handleNext}>
                        Next &raquo;
                    </Button>
                </Visibility>
            </EvenColumns>
        </CenterDiv>
    )

    function handlePrevious() {
        scrollToTop();
        onUpdatePage(meta.current_page - 1);
    }

    function handleNext() {
        scrollToTop();
        onUpdatePage(meta.current_page + 1);
    }

    function scrollToTop() {
        window.scrollTo(0, 0);
    }
}
