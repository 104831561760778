import React from 'react'
import styled from 'styled-components'
import Link from './../../styled/Link';
import Colors from '../../../themes/Colors';
import useAuthContext from '../../../context/AuthContext';
import Button from '../../Button';
import { useState } from 'react';



const NavItem = styled.div`
    padding: 1rem;
    white-space: nowrap;
    text-align: left;

    transition: background-color .4s;

    :hover {
        text-decoration: underline;
        background-color: ${Colors.primary.medium};
    }
`;

const ToggleButton = styled(Button)`
    width: 100%;
    padding: 0;

    ${NavItem}:hover {
        text-decoration: none;
    }
`;

const NavSpacer = styled.div`
    display: none;
    flex: 1;
`;

const NavigationBar = styled.nav`
    display: flex;
    flex-direction: column;
    background-color: ${Colors.primary.dark};
    color: white;

    ${Link} {
        ${props => !props.open ? 'display: none;' : null}
    }

    @media only screen and (min-width: 767px) {
        flex-direction: row;

        ${ToggleButton} {
            display: none;
        }

        ${Link}, ${NavSpacer} {
            display: block;
        }   
    }
`;


export default function Navigation() {
    const { user } = useAuthContext();
    const [navOpen, setNavOpen] = useState(false);

    return (
        <NavigationBar open={navOpen}>
            <ToggleButton onClick={() => handleNavButton(!navOpen)}>
                <NavItem>
                    {navOpen ? '⛌' : '☰'}
                </NavItem>
            </ToggleButton>
            <Link onClick={() => handleNavButton(false)} to={"/"}><NavItem>Home</NavItem></Link>
            <Link onClick={() => handleNavButton(false)} to={"/products"}><NavItem>Products</NavItem></Link>
            <Link onClick={() => handleNavButton(false)} to={"/brands"}><NavItem>Brands</NavItem></Link>
            <NavSpacer />
            {user ?
                <>
                    <Link onClick={() => handleNavButton(false)} to={"/dashboard"}><NavItem>Dashboard</NavItem></Link>
                    <Link onClick={() => handleNavButton(false)} to={"/logout"}><NavItem>Logout</NavItem></Link>
                </> :
                <>
                    <Link onClick={() => handleNavButton(false)} to={"/login"}><NavItem>Log in</NavItem></Link>
                    <Link onClick={() => handleNavButton(false)} to={"/register"}><NavItem>Register</NavItem></Link>
                </>
            }
        </NavigationBar>
    );

    function handleNavButton(state) {
        setNavOpen(state);
    }
}
