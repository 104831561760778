import useAuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const usePagePermission = (permission) => {
    const { user } = useAuthContext();
    const [validating, setValidating] = useState(true);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate(`/login?redirect=${window.location.pathname}`);
        } else if (!user.permissions || !user.permissions.includes(permission)) {
            navigate("/forbidden")
        } else {
            setValidating(false);
            setValidated(true);
        }

    }, [permission]);

    return { validating, validated }
};

const usePageRole = (role) => {
    const { user } = useAuthContext();
    const [validating, setValidating] = useState(true);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate(`/login?redirect=${window.location.pathname}`);
        } else if (!user.roles || !user.roles.includes(role)) {
            navigate("/forbidden")
        } else {
            setValidating(false);
            setValidated(true);
        }

    }, [role]);

    return { validating, validated }
};

const useLoggedIn = () => {
    const { user } = useAuthContext();
    const [validating, setValidating] = useState(true);
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate(`/login?redirect=${window.location.pathname}`);
        } else {
            setValidating(false);
            setValidated(true);
        }

    }, []);

    return { validating, validated }
}

export {useLoggedIn, usePagePermission, usePageRole};
export default usePagePermission;
