import styled from 'styled-components';

const Overlay = styled.div`
    display: block;
    z-index: 1000;
    font-size: 1.5rem;
    position:absolute;
    color:black;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #EEEA;
`;

const OverlayDisplay = styled.div`
    display: grid;
    place-content: center;
    width: 100%;
    height: 100%;
`;

const LoadingSpinner = styled(OverlayDisplay)`
    border-radius: 50%;
    aspect-ratio: 1/1;
    border-style: inset;
    padding: ${props => props.size || 2}rem;

    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 

    @keyframes spin {
    from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }
`;

const Container = styled.div`
    position: relative;
`;

export default function StatusOverlay({ loading, overlayMessage, size, children }) {
    return (
        <Container>
            {(loading === true || overlayMessage) &&
                <Overlay>
                    <OverlayDisplay>
                        {loading ? <LoadingSpinner size={size}></LoadingSpinner> : null}
                        {overlayMessage && overlayMessage}
                    </OverlayDisplay>
                </Overlay>}
            {children}
        </Container>
    )
}