import React from "react";
import StatusOverlay from './../StatusOverlay';

export default function Form({ loading, children, overlayMessage, ...props }) {
    return (
        <StatusOverlay loading={loading} overlayMessage={overlayMessage}>
            <form {...props} >
                {children}
            </form>
        </StatusOverlay>
    )
}
