import React from 'react'
import ScaledSquareImage from '../styled/ScaledSquareImage';
import styled from 'styled-components';
import EvenColumns from '../styled/EvenColumns';
import BoxShadow from '../../themes/BoxShadow';
import Colors from './../../themes/Colors';

const Relative = styled.div`
    position: relative;
`;

const PreviewImage = styled(ScaledSquareImage)`
    box-shadow: ${BoxShadow.size.small} ${Colors.gray.dark};
`;

const DropContainer = styled(EvenColumns)`
    border: 1px solid gray;
    aspect-ratio: ${props => props.maxImages}/1;
`;

const RemoveButton = styled.button`
    position: absolute;
    cursor: pointer;
    width: 10%;
    height: 10%;
    top: 0;
    right: 0;
    border: 0;
    padding: 0;
`;

export default function ImageUploadPreview({ images, onChange, maxImages }) {
    images = images || [];
    maxImages = maxImages || 1;
    const ALLOWED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

    return (
        <div>
            <span>Drop images below to upload. (PNG/JPG).( {images.length} / {maxImages} ) </span>
            <DropContainer columns={maxImages}
                padding={'1rem'}
                onDrop={e => handleDrop(e)}
                onDragOver={e => handleDragover(e)}>
                {images.map((image, index) => {
                    return (
                        <Relative key={index}>
                            <RemoveButton type='button' onClick={e => handleRemoveImage(index)}>⛌</RemoveButton>
                            <PreviewImage src={image.src} />
                        </Relative>
                    );
                })}
            </DropContainer>
        </div>
    );

    function handleDrop(e) {
        e.preventDefault();

        if (e.dataTransfer.items) {
            Array.from(e.dataTransfer.items).forEach(item => {
                if (item.kind === 'file') {
                    if (ALLOWED_FORMATS.includes(item.type)) {
                        let reader = new FileReader();

                        reader.onload = (e) => {
                            if (!images.some((image) => image.src === e.target.result)) {
                                images = [...images, { src: e.target.result }]
                                onChange(images);
                            }
                        }

                        reader.readAsDataURL(item.getAsFile())
                    }
                }
            })
        }

        if (images.length > maxImages) {
            alert(`A maximum of ${maxImages} images is permitted.`);
            return;
        }
    }

    function handleDragover(e) {
        e.preventDefault();
    }

    function handleRemoveImage(removeIndex) {
        onChange(images.filter((image, index) => {
            return index !== removeIndex;
        }))
    }
}
