import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from "../api/axios";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const csrf = () => fetch(process.env.REACT_APP_API_URL + "sanctum/csrf-cookie");

    const getUser = async () => {
        return await axios.get('/api/user').then(response => {
            setUser(response.data.data);
        }).catch(e => {

        });
    }

    const login = async ({ email, password }) => {
        await csrf();
        return await axios.post('/api/login', { email, password }).then(response => {
            if (!response?.data?.errors) {
                getUser().then(response => {
                    if (searchParams.has('redirect')) {
                        navigate(searchParams.get('redirect'));
                    } else {
                        navigate("/");
                    }
                })
            }
            return response;
        }).catch(e => {

        })
    }

    const register = async ({ username, email, password, password_confirmation }) => {
        await csrf();
        return await axios.post('/api/register', {
            username: username,
            email: email,
            password: password,
            password_confirmation: password_confirmation
        }).then(response => {
            getUser().then(() => {
                navigate("/");
            });
            return response;
        }).catch(error => {
            throw error;
        })
    }

    const logout = () => {
        axios.post('/api/logout').then(() => {
            setUser(null)
            navigate("/")
        }).catch(e => {

        })
    }

    return (<AuthContext.Provider value={{ user, getUser, login, register, logout }}>
        {children}
    </AuthContext.Provider>);
}

export default function useAuthContext() {
    return useContext(AuthContext);
}


