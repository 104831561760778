import React, { useState } from 'react'
import styled from 'styled-components';
import Button from './Button';
import Input from './styled/Input';

const SearchButton = styled(Button)`
    flex: 1;
    max-width: 5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const SearchInput = styled(Input)`
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
    border-right: none;
    flex: 1;
`;

const Container = styled.div`
    max-width: 70rem;
    display: flex;
    align-items: center;
    width: 100%;
`;

const Circle = styled.div`
    width: 1rem;
    aspect-ratio: 1;
    margin: auto;
    transform: translate(-0.2rem, -0.2rem);
    border-radius: 50%;
    border: .15rem solid white;
`;

const Handle = styled.div`
    position: absolute;
    height: 100%;
    aspect-ratio: 1/5;
    border-radius: 25%;
    background-color: white;
    top: 100%;
    left: 75%;
    transform-origin: 0 0;
    transform: rotate(-45deg);
`;

export default function SearchBar({ placeholder, onSearch }) {

    const [searchTerm, setSearchTerm] = useState("");

    return (
        <Container>
            <SearchInput placeholder={placeholder || ""} onKeyDown={e => checkEnter(e)} onChange={e => changeSearchTerm(e)} type="text" id='input' value={searchTerm} />
            <SearchButton onClick={search}>
                <Circle>
                    <Handle />
                </Circle>
            </SearchButton>
        </Container>
    )


    function changeSearchTerm(e) {
        setSearchTerm(e.target.value);
    }

    function checkEnter(e) {
        if (e.key == 'Enter') {
            search();
        }
    }

    function search() {
        if (searchTerm != "") {
            onSearch(searchTerm)
            setSearchTerm("");
        }
    }
}
