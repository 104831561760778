import styled from 'styled-components';
import React from 'react';
import Colors from './../themes/Colors';
import Button from './Button';


const StyledCloseButton = styled(Button)`
    background-color: transparent;
    color: ${Colors.primary.dark};
    font-weight: bolder;
    padding: 0;
    :hover {
        background-color: transparent;
    }
`;

export default function CloseButton({ type, children, ...props }) {
    return <StyledCloseButton type={'button'} {...props}>⛌</StyledCloseButton>
}