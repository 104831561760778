import React, { useState, useEffect } from "react";
import ProductEditor from "../components/forms/ProductEditor";
import { useParams } from 'react-router-dom';
import useAxios from './../hooks/useAxios';

export default function ProductEdit() {
  const params = useParams();
  const [product, setProduct] = useState(null);
  const { fetch } = useAxios();

  
  useEffect(() => {
    fetch(`/api/v1/products/${params.id}`).then(response => {
      setProduct(response.data.data);
    })
  }, [])

  return <ProductEditor product={product} />
}
