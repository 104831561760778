import React, { useEffect, useState } from 'react'
import ImageGallery from './../components/ImageGallery'
import useAxios from '../hooks/useAxios';
import CompactGrid from './../components/CompactGrid';
import InformationTable from './../components/InformationTable';
import ResetLink from '../components/styled/Link';
import { Link, useParams } from 'react-router-dom';
import StatusOverlay from './../components/StatusOverlay';
import styled from 'styled-components';
import CompactGridChild from './../components/CompactGridChild';
import BoxShadow from './../themes/BoxShadow';
import Title from './../components/styled/Title';
import Colors from './../themes/Colors';
import Subtitle from './../components/styled/Subtitle';
import Button, { HighlightButton } from './../components/Button';
import Overlay from '../components/Overlay';
import ProductReportForm from '../components/forms/ProductReportForm';
import CloseButton from './../components/CloseButton';
import DOMPurify from 'dompurify';
import { useTitle } from './../hooks/useTitle';
import Center from './../components/styled/Center';

const Layout = styled(CompactGrid)`
    grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr));
`;

const Container = styled(CompactGridChild)`
    box-shadow: ${BoxShadow.size.medium} ${Colors.gray.light};
    margin: 1rem;
    padding: 1rem;
`;

const FormOverlay = styled(Overlay)`
    display: grid;
    place-content: center;
`;

const ImageContainer = styled(Container)``;

const Description = styled.p`
    overflow-wrap: break-word;
`;


const ReportFormContainer = styled.div`
    width: 20rem;
    background-color: white;
    border: 1px solid black;
    border-radius: 5%;
    padding: .5rem;
`;


export default function Product() {
    const params = useParams();
    const [error, setError] = useState();
    const [product, setProduct] = useState({});
    const [reportFormVisible, setReportFormVisible] = useState(false);
    const { fetch, loading } = useAxios();
    const [activeListings, setActiveListings] = useState([]);

    useTitle(product?.title);
    useEffect(() => {
        fetch(`/api/v1/products/${params.id}`)
            .then(handleResponse)
            .catch(error => {
                setError(error);
            }
            );
    }, [params.id])

    return (
        <StatusOverlay loading={loading} overlayMessage={error ? 'Error loading product.' : null}>
            <FormOverlay visible={reportFormVisible}>
                <ReportFormContainer>
                    <CloseButton onClick={() => setReportFormVisible(false)} align={'right'}></CloseButton>
                    <ProductReportForm productId={product.id} />
                </ReportFormContainer >
            </FormOverlay>
            <Layout >
                <ImageContainer >
                    <Title fontSize={1.2}>{product?.title}</Title>
                    <Button size={'small'} align={'right'} margin={'.5rem'} onClick={() => setReportFormVisible(true)}>Report</Button>
                    <ImageGallery images={product?.images || []}></ImageGallery>
                </ImageContainer>
                {activeListings.length > 0 && <Container>
                    <Title>Listings</Title>
                    <Subtitle fontSize={.8}>Some or all of these links may provide compensation to XtotheSpot through referral.</Subtitle>
                    <InformationTable
                        headers={["Storefront", "Price", "Last Price Check", "Link"]}
                        data={activeListings} />
                </Container>}
                {product?.description && <Container>
                    <Title fontSize={1.2}>Description</Title>
                    <Description dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product?.description.body) }} />
                </Container>}
                <Container>
                    <Title fontSize={1.2}>Details</Title>
                    <InformationTable
                        data={[
                            ["UPC", product?.upc],
                            ["Brand", product?.brand?.name && <span>{product.brand.name} (<Link to={`/brands/${product?.brand?.id}`}>brand page</Link>)</span>],
                            ["Model", product?.model],
                            ["Weight (lb)", product?.weight?.toFixed(2)],
                            ["Length (in.)", product?.length?.toFixed(2)],
                            ["Width (in.)", product?.width?.toFixed(2)],
                            ["Height (in.)", product?.height?.toFixed(2)],
                        ]}
                    />
                </Container>
                {product?.video?.src && <Container>
                    <Title fontSize={1.2}>Video: {product.video.title}</Title>
                    <iframe style={{ width: '100%', aspectRatio: '16/9' }} src={product?.video?.src}></iframe>
                </Container>}
            </Layout>
        </StatusOverlay>
    );

    function handleResponse(response) {
        let productData = response.data.data;
        setProduct(productData);

        if (productData.listings) {
            setActiveListings(productData.listings.filter(listing => {
                return listing.is_active === 1;
            }).sort((a, b) => { return a.price?.price ?? 0 - b.price?.price ?? 1 }).map(listing => {
                return [
                    <Center>
                        <Link to={`/storefronts/${listing.storefront.id}`}>
                            {listing.storefront.name}
                        </Link>
                    </Center>,
                    <Center>
                        {listing.price?.price ? `$${listing.price.price}` : 'No data.'}
                    </Center>,
                    <Center>
                        {listing.price?.updated_at ? new Date(listing.price.updated_at).toLocaleDateString() : 'NA'}
                    </Center>,
                    <Center>
                        <ResetLink to={listing.url}>
                            <HighlightButton highlight size={"small"}>Buy on {listing.storefront.name}</HighlightButton>
                        </ResetLink>
                    </Center>
                ];
            }));
        }
    }
}
