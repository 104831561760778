import React from 'react';
import Footer from '../footer/Footer';
import SearchHeader from '../header/SearchHeader';
import Wrapper from '../../styled/Wrapper';
import Navigation from '../navigation/Navigation';
import { Outlet } from 'react-router-dom';
import FooterLinks from '../footer/FooterLinks';

export default function StandardLayout({ children }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }}
    >
      <SearchHeader />
      <Navigation />
      <Wrapper>
        <Outlet />
      </Wrapper>
      <Footer />
      <FooterLinks />
    </div>
  )
}
