import { Link as DefaultLink } from 'react-router-dom';

import styled from 'styled-components';

const Link = styled(DefaultLink)`
    color: inherit;
    text-decoration: none;
`;


export default Link;
