import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Title from '../components/styled/Title';
import useObject from '../hooks/useObject';
import useAuthContext from '../context/AuthContext';
import EvenColumns from './../components/styled/EvenColumns';
import Button from './../components/Button';
import Form from './../components/forms/Form';
import Grid from './../components/styled/Grid';
import { useTitle } from './../hooks/useTitle';

export default function Register() {
    const navigate = useNavigate();
    const passwordConfirmation = useRef();
    const { handleChange, object: formData } = useObject({});
    const { register, user } = useAuthContext();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    useTitle('XtotheSpot Register')

    useEffect(() => {
        if(user) {
            navigate("/");
        }
    }, [])

    const submitRegister = (e) => {
        e.preventDefault();
        if (checkConfirmation()) {
            setLoading(true);
            register(formData)
                .then(setLoading(false))
                .catch(error => {
                    setLoading(false);
                    setErrors(error.response?.data?.errors);
                });
        }
    }

    const checkConfirmation = () => {
        if (formData.password !== formData.password_confirmation) {
            passwordConfirmation.current.setCustomValidity('Password confirmation must match.');
            return false;
        }
        passwordConfirmation.current.setCustomValidity('');
        return true;
    }

    return (
        <Form loading={loading}
            onSubmit={submitRegister}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            style={{ width: 'fit-content', marginInline: 'auto' }}>
            <Title>Register</Title>
            <Grid gap={'1rem'}>
                <EvenColumns columns={2}>
                    <label htmlFor="username">Username:</label>
                    <input type="text" name='username' required />
                    <label htmlFor="email">Email:</label>
                    <input type="email" name='email' required />
                    <label htmlFor="password">Password:</label>
                    <input type="password" name='password' required />
                    <label htmlFor="password_confirmation">Confirm Password:</label>
                    <input ref={passwordConfirmation} type="password" name='password_confirmation' required />
                </EvenColumns>
                {errors ?
                    <div>
                        {Object.keys(errors || {}).map(err => {
                            return errors[err].map(message => {
                                return <div key={message} style={{ gridColumn: 'span 2', color: 'red' }}>
                                    {message}
                                </div>
                            })
                        })}
                    </div> : ''
                }
                <p>Already have an account? <Link to={'/login'}>Log in here.</Link></p>
                <Button type={'submit'} align={'right'}>Create Account</Button>
            </Grid>
        </Form>
    )
}
