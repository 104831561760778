import React, { useState, useRef } from 'react'
import styled from 'styled-components';

const Child = styled.div`
    ${props => {
        if (props.direction === 'column') {
            return `grid-column: span ${Math.ceil(props.width / 10)};`
        } else {
            return `grid-row: span ${Math.ceil(props.height / 10)};`
        }
    }}
`;

export default function CompactGridChild({ children, direction, className }) {

    const targetDiv = useRef();
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    setInterval(() => {
        if (targetDiv) {
            setHeight(targetDiv.current?.clientHeight);
            setWidth(targetDiv.current?.clientWidth);
        }
    }, 200);

    return (
        <Child height={height} width={width} direction={direction} className={className}>
            <div ref={targetDiv}>
                {children}
            </div>
        </Child>
    )
}