import React from 'react'
import HeaderLogo from '../../../assets/images/HeaderLogo.png';
import { createSearchParams, useNavigate } from 'react-router-dom';
import SearchBar from './../../SearchBar';
import styled from 'styled-components';
import Colors from './../../../themes/Colors';
import Link from './../../styled/Link';

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem;
    gap: 1rem;
    background-color: ${Colors.primary.light};
`;

export default function SearchHeader() {

    const navigate = useNavigate();

    const handleSearch = (searchTerm) => {
        let searchParams = { page: 1, 'title[ct]': searchTerm };
        navigate({
            pathname: '/products',
            search: `?${createSearchParams(searchParams)}`
        });
    }

    return (
        <>
            <Header>
                <Link to={"/"}>
                    <img src={HeaderLogo} alt="Logo" />
                </Link>
                <SearchBar placeholder={'Spot a product...'} onSearch={(handleSearch)}></SearchBar>
            </Header>
        </>
    )
}
