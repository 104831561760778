import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button';
import Title from '../components/styled/Title';
import HeaderLogo from "../assets/images/HeaderLogo.png";
import Link from '../components/styled/Link';
import { useTitle } from './../hooks/useTitle';

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    place-content: center;
    margin-inline: auto;
    margin-top: 10%;
`;

export default function ErrorPage({ error }) {
    useTitle(error);
    return (
        <Layout>
            <div>
                <img src={HeaderLogo} alt="" />
            </div>
            <Title fontSize={1.5}>{error}</Title>
            <Link to={"/"}>
                <Button align={'right'}>Go Home</Button>
            </Link>
        </Layout>
    )
}
