import React, { useEffect, useState } from 'react'
import useAxios from '../hooks/useAxios';
import StatusOverlay from './../components/StatusOverlay';
import Title from './../components/styled/Title';
import Paginator from './../components/page/footer/Paginator';
import ImageCardList from './../components/ImageCardList';
import Link from './../components/styled/Link';
import { useSearchParams } from 'react-router-dom';
import ImageCard from '../components/ImageCard';
import ImageNotFound from "../assets/images/ImageNotFound.jpg"
import styled from 'styled-components';
import Colors from '../themes/Colors';
import Subtitle from './../components/styled/Subtitle';
import Spacer from './../components/styled/Spacer';
import Button from '../components/Button';
import SidebarLayout from '../components/page/layout/SidebarLayout';
import { useTitle } from './../hooks/useTitle';

const CategoryLink = styled.a`
    display: block;
    user-select: none;
    cursor: pointer;
    color: ${Colors.primary.dark};
    padding: .2rem 1rem;
    :hover {
        text-decoration: underline;
        background-color: ${Colors.gray.light};
    }
`;

const ProductCard = styled(Link)`
    max-width: 35rem;
`;

export default function Products() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState(null);
    const [data, setData] = useState([])
    const [meta, setMeta] = useState(null)
    const { fetch, loading } = useAxios();

    useTitle('XtotheSpot Products');

    useEffect(() => {
        fetch(`/api/v1/products?` + searchParams).then(handleResponse);

        fetch(`/api/v1/categories?${searchParams}`).then(response => {
            setCategories(response?.data?.data);
        })
    }, [searchParams])

    useEffect(() => {
        if (searchParams.has('category_id')) {
            fetch(`/api/v1/categories/${searchParams.get('category_id')}`).then(response => {
                setCategory(response?.data?.data);
            })
        } else {
            setCategory(null);
        }
    }, [searchParams.get('category_id')])

    return (
        <SidebarLayout openText={'Filters'} closeText={'Hide Filters'}>
            <>
                <Title>Category</Title>
                <Subtitle fontSize={1.1}>
                    {
                        breadcrumb(category).map((crumb, index) => {
                            return (<span key={crumb.id}>
                                {index !== 0 ? ' > ' : null}
                                <CategoryLink onClick={() => handleCategorySelection(crumb)}> {crumb.name}</CategoryLink>
                            </span>
                            );
                        })
                    }
                </Subtitle>
                <Spacer height={'1rem'}></Spacer>
                {category &&
                    <Button size={'small'} onClick={() => handleCategorySelection(null)}>Clear Category</Button>
                }
                {categories.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <CategoryLink onClick={() => handleCategorySelection(item)} >{item.name}</CategoryLink>
                        </React.Fragment>
                    );
                })}
            </>
            <StatusOverlay loading={loading} top={'10%'}>
                <ImageCardList columnWidth={"20rem"}>
                    {data.map((product => {
                        return (
                            <ProductCard key={product.id} to={`/products/${product.id}`}>
                                <ImageCard imageSrc={product.images && (product.images[0].src ?? ImageNotFound)}>
                                    <Title>{product.title}</Title>
                                    <span>{getLowestPrice(product) ?? 'No price data.'}</span>
                                </ImageCard>
                            </ProductCard>
                        )
                    }))}
                </ImageCardList>
                <Paginator meta={meta} onUpdatePage={handlePageChange} />
            </StatusOverlay >
        </SidebarLayout>
    )

    function handlePageChange(page) {
        searchParams.set('page', page);
        setSearchParams(searchParams);
    }

    function handleResponse(response) {
        setData(response.data.data);
        setMeta(response.data.meta);
    }

    function handleCategorySelection(category) {
        if (category == null) {
            searchParams.delete('category_id');
        } else {
            searchParams.set('category_id', category.id);
        }
        searchParams.set('page', 1);
        setSearchParams(searchParams);
    }

    function getLowestPrice(product) {
        if (!product?.listings) return null;

        let lowest = null;

        product.listings.forEach(listing => {
            if (listing.price?.price) {
                if (lowest == null || listing.price.price < lowest) {
                    lowest = listing.price.price;
                }
            }
        })

        if (lowest) {
            return `$${lowest}`;
        }
        return lowest;
    }

    function breadcrumb(category, crumbs = []) {
        if (!category) return [];

        crumbs.unshift(category)
        if (!category.category) {
            return crumbs;
        }

        return breadcrumb(category.category, crumbs);
    }
}
