import React, { useEffect, useState } from 'react'
import Form from './../components/forms/Form';
import ProductForm from './../components/forms/ProductForm';
import { useNavigate } from 'react-router-dom';
import useObject from './../hooks/useObject';
import usePagePermission from './../hooks/usePagePermission';
import useAxios from './../hooks/useAxios';
import Loading from './Loading';

export default function ProductNew() {
    const navigate = useNavigate();
    const [overlayMessage, setOverlayMessage] = useState();
    const { fetch, loading } = useAxios()
    const { handleChange, object: formData } = useObject();
    const { validating, validated } = usePagePermission('product-store');

    if (validating || !validated) return <Loading />

    return (
        <Form
            method={'POST'}
            onSubmit={e => handleSubmit(e)}
            overlayMessage={overlayMessage}
            loading={loading}>
            <ProductForm
                onChange={handleChange}
                formData={formData}
                submitText={'Create'}
            ></ProductForm>
        </Form>
    )


    function handleSubmit(e) {
        e.preventDefault();

        createProduct().then(
            response => {
                if (response.status !== 201) {
                    alert('Product not created.');
                } else {
                    let promises = [];
                    const productId = response.data.data.id;

                    promises.push(createImages(productId));
                    promises.push(createVideo(productId));
                    promises.push(createDescription(productId));

                    Promise.allSettled(promises).then(() => {
                        setOverlayMessage('Product Created...');
                        setTimeout(() => {
                            navigate(`/products/${productId}/edit`);
                        }, 500)
                    })
                }
            }
        );
    }

    function createProduct() {
        return fetch('/api/v1/products', {
            method: 'POST', data: {
                title: formData.title,
                upc: formData.upc,
                model: formData.model,
                brand_id: formData.brand_id,
                weight: formData.weight,
                length: formData.length,
                width: formData.width,
                height: formData.height
            }
        })
    }

    function createImages(productId) {
        return new Promise((resolve, reject) => {
            let promises = [];
            promises.push(formData.images.map((image, index) => {
                return fetch('/api/v1/product/images', { method: 'POST', data: { ...image, product_id: productId } })
            }));
            Promise.allSettled(promises).then(
                resolve()
            );
        })
    }

    function createVideo(productId) {
        return new Promise((resolve, reject) => {
            if (formData.video?.src && formData.video?.title) {
                fetch('/api/v1/product/videos', { method: 'POST', data: { ...formData.video, product_id: productId } })
                    .then(resolve)
                    .catch(reject);
            } else {
                resolve();
            }
        })
    }

    function createDescription(productId) {
        return new Promise((resolve, reject) => {
            if (formData.description?.body) {
                fetch('/api/v1/product/descriptions', { method: 'POST', data: { ...formData.description, product_id: productId } })
                    .then(resolve)
                    .catch(reject);
            } else {
                resolve();
            }
        })
    }
}
