import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../../Button';
import Spacer from '../../styled/Spacer';
import Colors from '../../../themes/Colors';

const Sidebar = styled.div`
    background-color:white;
    border-right: 1px solid ${Colors.secondary.dark};
    margin-right: 2rem;
`;

const Layout = styled.div`
    display: grid;
    grid-template-columns: 0 1fr;
    ${props => {
        if (!props.menuOpened) {
            return `
                ${Sidebar} {
                opacity: 0;
                width: 1px;
                height: 1px;
                overflow: hidden;
            }`
        } else {
            return `grid-template-columns: min-content 1fr;`;
        }
    }}
`;


export default function SidebarLayout({ openText, closeText, children }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    openText = openText ?? 'Menu';
    closeText = closeText ?? 'Close Menu';

    return (
        <div>
            <Button size={'small'} onClick={handleSidebarOpen}>{sidebarOpen ? closeText : openText}</Button>
            <Spacer height={"1rem"} />
            <Layout menuOpened={sidebarOpen}>
                <Sidebar>
                    {children[0]}
                </Sidebar>
                <div>
                    {children[1]}
                </div>
            </Layout>
        </div>
    );


    function handleSidebarOpen() {
        setSidebarOpen(prevOpen => { return !prevOpen });
    }
}
