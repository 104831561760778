import React, { useEffect, useRef, useState } from "react";
import { usePageRole } from "../hooks/usePagePermission";
import { useTitle } from './../hooks/useTitle';
import Label from "../components/styled/Label";
import useAxios from "../hooks/useAxios";
import Flex from "../components/styled/Flex";
import styled from "styled-components";

const ListingContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
`;

export default function Merchant() {
    const [createListingFile, setCreateListingFile] = useState('');
    const [listingPriceQuantityFile, setListingPriceQuantityFile] = useState('');
    const createListingFileInput = useRef();
    const listingPriceQuantityFileInput = useRef();
    const { fetch } = useAxios();

    const [listings, setListings] = useState([]);

    usePageRole('Merchant');
    useTitle('XtotheSpot Merchant Dashboard');

    useEffect(() => {
        fetch('/api/v1/listings').then(response => {
            console.log(response.data);
            setListings(response.data.data);
        })
    }, [])

    return (<>
        <Flex gap={'1rem'}>
            <Label htmlFor='create-listing-file'
                title="upc,sku,url"
            >Add Listings by UPC Match</Label>
            <input ref={createListingFileInput} name='create-listing-file' type={'file'} accept=".csv" onChange={(e) => setCreateListingFile(e.target.files[0])} />
            <button onClick={handleCreateListingUpload}>Upload</button>
        </Flex>
        <Flex gap={'1rem'}>
            <Label htmlFor='listing-price-quantity-file'
                title="sku,price,quantity"
            >Upload Price/Quantity</Label>
            <input ref={listingPriceQuantityFileInput} name='listing-price-quantity-file' type={'file'} accept=".csv" onChange={(e) => setListingPriceQuantityFile(e.target.files[0])} />
            <button onClick={handlePriceQuantityUpload}>Upload</button>
        </Flex>

        {
            listings &&
            <ListingContainer>
                <h4>Title</h4>
                <h4>SKU</h4>
                <h4>Price</h4>
                <h4>Quantity</h4>
                {
                    listings.map(listing => {

                        return (
                            <React.Fragment key={listing.id}>
                                <span>{listing.product.title}</span>
                                <span>{listing.sku}</span>
                                <span>{listing.price?.price ?? 0}</span>
                                <span>{listing.quantity?.quantity ?? 0}</span>

                            </React.Fragment>
                        )
                    })
                }
            </ListingContainer>
        }
    </>);


    function handleCreateListingUpload() {
        if (!createListingFile) return;

        let formData = new FormData();
        formData.append('createListingFile', createListingFile, createListingFile.name);
        fetch('/api/v1/listing/match', { method: 'POST', data: formData }).then(response => {
            setCreateListingFile('');
            createListingFileInput.current.value = null;
        }).catch(error => { })
    }

    function handlePriceQuantityUpload() {
        if (!listingPriceQuantityFile) return;

        let formData = new FormData();
        formData.append('listingPriceQuantityFile', listingPriceQuantityFile, listingPriceQuantityFile.name);
        fetch('/api/v1/listing/pricequantity', { method: 'POST', data: formData }).then(response => {
            setListingPriceQuantityFile('');
            listingPriceQuantityFileInput.current.value = null;
        }).catch(error => { })
    }
}
