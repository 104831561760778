import React from 'react'
import { useState, useEffect } from 'react';
import useAxios from '../../hooks/useAxios';
import SearchableSelect from './SearchableSelect';
import StatusOverlay from '../StatusOverlay';
import ValidInvalid from '../ValidInvalid';
import EvenColumns from '../styled/EvenColumns';

export default function ProductBrandEditor({ brandId, onBrandChange }) {

    const [suggestions, setSuggestions] = useState([]);
    const [input, setInput] = useState("");
    const { fetch, loading } = useAxios();

    useEffect(() => {
        searchBrands();
    }, [input, brandId])

    function searchBrands() {
        if (brandId && input == "") {
            fetch('api/v1/brands/' + brandId).then(response => {
                setInput(response.data.data.name)
            })
        } else if (input === "") {
            setSuggestions([]);
        } else {
            fetch('api/v1/brands?name[sw]=' + input).then(response => {
                updateSuggestions(response.data.data);
                checkBrandValid(response.data.data);
            })
        }
    }

    function updateSuggestions(data) {
        checkBrandValid(data);

        if (data.length === 1 && data[0].name.toLowerCase() === input.toLowerCase()) {
            setSuggestions([]);
        } else {
            setSuggestions(data);
        }
    }

    function checkBrandValid(data) {
        data = data || [];

        let brand_found = false;

        data.forEach(brand => {
            if (brand.name === input) {
                onBrandChange(brand.id);
                brand_found = true;
            }
        })

        if (!brand_found) {
            onBrandChange(null);
        }
    }

    return (
        <EvenColumns columns={2}>
            <SearchableSelect
                onChange={newValue => setInput(newValue)}
                input={input}
                suggestions={suggestions.map(suggestion => {
                    return { "id": suggestion.id, "value": suggestion.name }
                })}
            />
            <StatusOverlay loading={loading} size={.5} left={'0%'} top={'0%'}>
                <ValidInvalid boolean={brandId ? true : false} />
            </StatusOverlay>
        </EvenColumns>
    )
}
