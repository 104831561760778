import React from 'react'
import { Outlet } from 'react-router-dom';
import Colors from '../../../themes/Colors';
import Link from '../../styled/Link';
import SidebarLayout from './SidebarLayout';
import styled from 'styled-components';
import useAuthContext from '../../../context/AuthContext';
import { useLoggedIn } from '../../../hooks/usePagePermission';

const SidebarLink = styled(Link)`
    cursor: pointer;
    margin: .2rem 0;
    width: 100%;
    padding: 1rem 2rem;

    :hover {
        background-color: ${Colors.gray.light}
    }
`;

const List = styled.div`
    display: flex;
    flex-direction: column;
    padding 1rem 0;
`;

export default function DashboardLayout() {
    const { user } = useAuthContext();
    useLoggedIn();

    return (
        <SidebarLayout>
            <List>
                <SidebarLink to={"/dashboard/account"}>Account</SidebarLink>
                {user && <SidebarLink to={"/dashboard/catalog"}>Catalog</SidebarLink>}
                {user && user.roles.includes('Merchant') && <SidebarLink to={"/dashboard/merchant"}>Merchant</SidebarLink>}
                {user && user.roles.includes('Integration') && <SidebarLink to={"/dashboard/integration"}>Integration</SidebarLink>}
                <SidebarLink to={"/logout"}>Logout</SidebarLink>
            </List>
            <div>
                <Outlet />
            </div>
        </SidebarLayout>
    )
}
