import React, { useState, useEffect } from 'react'
import EvenColumns from '../components/styled/EvenColumns';
import Form from './../components/forms/Form';
import Input from './../components/styled/Input';
import Label from './../components/styled/Label';
import Button from './../components/Button';
import useAxios from './../hooks/useAxios';
import usePagePermission from './../hooks/usePagePermission';
import { useParams } from 'react-router-dom';
import Textarea from './../components/styled/Textarea';
import useObject from './../hooks/useObject';
import ImageUploadPreview from './../components/forms/ImageUploadPreview';
import styled from 'styled-components';
import Title from './../components/styled/Title';
import Spacer from './../components/styled/Spacer';

const Description = styled(Textarea)`
    flex: 1;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export default function BrandEdit() {
  usePagePermission('brand-update');
  const { fetch, loading } = useAxios();
  const [initialBrand, setInitialBrand] = useState({});
  const { handleChange, setObject, object: formData } = useObject({
    name: "",
    description: "",
    image: ""
  });
  const params = useParams();

  useEffect(() => {
    fetch(`/api/v1/brands/${params.id}`).then(response => {
      let brand = response.data.data;
      let brandObject = {
        name: brand.name ?? "",
        description: brand.description ?? "",
        image: brand.src ?? "",
      };
      setObject(brandObject);
      setInitialBrand(brandObject);
    }).catch(error => {
    })
  }, [params])

  return (
    <Form method="POST" onSubmit={handleSubmit} loading={loading}>
      <Title>Edit Brand</Title>
      <Button type={"submit"} align={"right"}>Edit</Button>
      <Spacer height={"1rem"}></Spacer>
      <EvenColumns columns={2} gap={"1rem"}>
        <ImageUploadPreview images={formData.image != "" ? [{ src: formData.image }] : []} maxImages={1} onChange={imageChange} />
        <Container>
          <EvenColumns columns={2} gap={"1rem"}>
            <Label htmlFor="name">Brand Name</Label>
            <Input name="name" value={formData.name} onChange={e => { handleChange(e.target.name, e.target.value) }} ></Input>
          </EvenColumns>
          <Label htmlFor="description">Description</Label>
          <Description name="description" value={formData.description} onChange={e => { handleChange(e.target.name, e.target.value) }}></Description>
        </Container>
      </EvenColumns>
    </Form>
  );

  function imageChange(images) {
    if (images.length === 0) {
      handleChange('image', "");
    } else {
      handleChange('image', images[0].src);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    let patchData = {};
    if (formData.name !== initialBrand.name) {
      patchData.name = formData.name;
    }
    if (formData.description !== initialBrand.description) {
      patchData.description = formData.description;
    }
    if (formData.image !== initialBrand.image) {
      patchData.image = formData.image;
    }
    if (Object.keys(patchData).length !== 0) {
      fetch(`/api/v1/brands/${params.id}`, {
        method: "PATCH",
        data: patchData
      });
    }
  }
}
