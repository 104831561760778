// CSS
import "./css/global.css";

// Libraries
import { Routes, Route, useParams, Navigate, createSearchParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';

// Pages
import Products from "./pages/Products";
import Product from "./pages/Product";
import ProductNew from "./pages/ProductNew";
import ProductEdit from "./pages/ProductEdit";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Brands from "./pages/Brands";
import Loading from "./pages/Loading";
import ErrorPage from './pages/ErrorPage';
import Account from "./pages/Account";
import BrandEdit from './pages/BrandEdit';
import BrandNew from './pages/BrandNew';

// Other
import StandardLayout from "./components/page/layout/StandardLayout";
import DashboardLayout from './components/page/layout/DashboardLayout';
import useAuthContext from "./context/AuthContext";
import StorefrontEdit from "./pages/StorefrontEdit";
import Storefront from './pages/Storefront';
import Brand from './pages/Brand';
import Merchant from './pages/Merchant';
import Integration from "./pages/Integration";
import Catalog from "./pages/Catalog";
import ProductReview from './pages/ProductReview';

function App() {
  const params = useParams();
  const { getUser } = useAuthContext();
  const [initializing, setInitializing] = useState(true);

  // Google Analytics
  if (process.env.REACT_APP_ENV !== 'dev') {
    ReactGA.initialize("G-MTLTKJ81CJ");
  }

  useEffect(() => {
    getUser().then(() => {
      setInitializing(false);
    })
  }, [])

  if (initializing) return <Loading />

  return (
    <>
      <Routes>
        <Route element={<StandardLayout />}>
          <Route path={"/"} element={<Products />} />
          <Route path={"/register"} element={<Register />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/logout"} element={<Logout />} />

          <Route path={"/products"} element={<Products />} />
          <Route path={"/products/:id"} element={<Product />} />
          <Route path={"/products/:id/edit"} element={<ProductEdit />} />
          <Route path={"/products/new"} element={<ProductNew />} />
          <Route path={"/products/review"} element={<ProductReview />} />
          <Route path={"/products/review/:id"} element={<ProductReview />} />


          <Route path={"/brands"} element={<Brands />} />
          <Route path={"/brands/:id"} element={<Brand />} />
          <Route path={"/brands/new"} element={<BrandNew />} />
          <Route path={"/brands/:id/edit"} element={<BrandEdit />} />

          <Route path={"/storefronts/:id"} element={<Storefront />} />
          <Route path={"/storefronts/:id/edit"} element={<StorefrontEdit />} />

          <Route path={"/dashboard"} element={<DashboardLayout />}>
            <Route path="/dashboard" element={<Account />} />
            <Route path="/dashboard/account" element={<Account />} />
            <Route path="/dashboard/catalog" element={<Catalog />} />
            <Route path="/dashboard/merchant" element={<Merchant />} />
            <Route path="/dashboard/integration" element={<Integration />} />
          </Route>
        </Route>
        <Route path={"/forbidden"} element={<ErrorPage error={'You do not have access to this resource.'} />} />
        <Route path={"*"} element={<ErrorPage error={'Whoops! That page does not exist.'} />} />
      </Routes>
    </>
  );
}

export default App;
