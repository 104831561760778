const Colors = {
    primary: {
        light: ' #87CEEB ',
        medium: ' #1E90FF ',
        dark: ' #1B55B7 ',
        darker: ' #031B45 ',
    },
    secondary: {
        light: ' #AF1607 ',
        medium: ' #9B1306 ',
        dark: ' #881106 ',
    },
    gray: {
        light: ' #e7e7e7 ',
        medium: ' #d6d6d6 ',
        dark: ' #c5c5c5 ',
    }
}

export default Colors;