import React, { useState } from "react";
import { usePageRole } from "../hooks/usePagePermission";
import { useTitle } from "../hooks/useTitle";
import Input from "../components/styled/Input";
import Button from "../components/Button";
import EvenColumns from "../components/styled/EvenColumns";
import useAxios from './../hooks/useAxios';

export default function Integration() {
    usePageRole('Integration');
    useTitle('XtotheSpot App Integration');
    const [key, setKey] = useState("");
    const { fetch } = useAxios();


    return (
        <div>
            Shopify Integration Key:
            <EvenColumns columns={2} gap={"1rem"}>
                <Input value={key} ></Input>
                <Button onClick={generateKey} size={"small"}>Generate Token</Button>
            </EvenColumns>
            <p><strong>WARNING: </strong>Keep this key private!</p>
        </div>

    );

    function generateKey() {
        fetch("/api/token/merchant").then(response => {
            setKey(response.data.merchant_token);
        })
    }
}
