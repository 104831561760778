import React, { useState } from 'react'
import styled from 'styled-components';
import Button from '../Button';
import Title from '../styled/Title';
import EvenColumns from './../styled/EvenColumns';
import Label from './../styled/Label';
import Textarea from './../styled/Textarea';
import Form from './Form';
import CloseButton from './../CloseButton';
import useAxios from './../../hooks/useAxios';
import useObject from './../../hooks/useObject';
import { useEffect } from 'react';

const ReportForm = styled(Form)`
    padding: 1rem;
`;

const ReportNote = styled(Textarea)`
    resize: none;
    height: 10rem;
    overflow-y: scroll;
`;

export default function ProductReportForm({ productId }) {
    const { fetch, loading } = useAxios();
    const { handleChange, getValue, object: formData } = useObject();
    const [overlayMessage, setOverlayMessage] = useState(null);
    const [reportTypes, setReportTypes] = useState([]);

    useEffect(() => {
        fetch('/api/v1/product/report/types').then(response => {
            setReportTypes(response?.data?.data);
        });
    }, [])

    return (
        <ReportForm onSubmit={handleSubmit} loading={loading} overlayMessage={!productId ? 'Error.' : overlayMessage}>
            <Title>Report Product</Title>
            <EvenColumns columns={1} gap={'1rem'}>
                <Label htmlFor="product_report_type_id">Reason</Label>
                <select name='product_report_type_id' required value={getValue('product_report_type_id') ?? ""} onChange={(e) => handleChange(e.target.name, e.target.value)}>
                    <option key={'default'} value="" disabled></option>
                    {reportTypes.map(type => {
                        return <option key={type.id} value={type.id}>{type.name}</option>
                    })}
                </select>
                <Label htmlFor="note">Details</Label>
                <ReportNote name="note" value={getValue('note')} onChange={(e) => handleChange(e.target.name, e.target.value)}></ReportNote>
            </EvenColumns>
            <Button align={'center'} type={'submit'} margin={'1rem'}>Report</Button>
        </ReportForm>
    );

    function handleSubmit(e) {
        e.preventDefault();

        fetch('/api/v1/product/reports', {
            method: 'POST',
            data: {
                product_id: productId,
                ...formData
            }
        }).then(response => {
            setOverlayMessage('Report submitted.');
        }).catch(err => {
            setOverlayMessage('Error submitting report.')
        })
    }

}
